import {
  FETCH_IH_TRANSFERS_REQUEST,
  FETCH_IH_TRANSFERS_SUCCESS,
  FETCH_IH_TRANSFERS_FAILURE,
  REFRESH_IH_TRANSFERS
} from './ihTransferTypes';

const initialState = {
  loading: false,
  ihTransfers: [],
  error: ''
}

const ihTransferReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_IH_TRANSFERS_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_IH_TRANSFERS_SUCCESS: return {
      loading: false,
      ihTransfers: action.payload,
      error: ''
    }
    case FETCH_IH_TRANSFERS_FAILURE: return {
      loading: false,
      ihTransfers: [],
      error: action.payload
    }

    //NOT SURE WE NEED TO REFRESH THESE IN THIS SIMPLE COMPONENT

    // case REFRESH_IH_TRANSFERS: {
    //   const storedIHTransfers = localStorage.getItem('ih_transfers');
    //   if (storedIHTransfers) {
    //     try {
    //       const ihTransfers = JSON.parse(storedIHTransfers);
    //       if (ihTransfers.data && ihTransfers.data.length > 0) {
    //         return {
    //           ...state,
    //           ihTransfers: ihTransfers.data
    //         }
    //       }
    //       else {
    //         return {
    //           ...state,
    //           ihTransfers: []
    //         }
    //       }
    //     } catch (e) {}
    //   }
    // }
    default: return state
  }
}

export default ihTransferReducer;