import { FETCH_SEASONS_REQUEST,
  FETCH_SEASONS_SUCCESS,
  FETCH_SEASONS_FAILURE,
  CHECK_SEASONS
} from './seasonTypes';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchSeasons = () => {
  //console.log('FETCHING SEASONS');
  const path = '/jsonapi/node/season';
  const sort = 'sort[sort-season][path]=field_season_start_date&sort[sort-season][direction]=DESC';
  const url = `${path}?${sort}`;
  const options = {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return async (dispatch) => {
    dispatch(fetchSeasonsRequest);
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const seasons = data.data;
        //console.log('SEASON DATA', seasons);
        localStorage.setItem('seasons', JSON.stringify(seasons));
        dispatch(fetchSeasonsSuccess(seasons));
      }
      else {
        throw new Error(`message: ${response.status}`);
      }
    }
    catch (error) {
      const errorMsg = error.message;
      dispatch(fetchSeasonsFailure(errorMsg));
    }
  }
}

export const fetchSeasonsRequest = () => {
  return {
    type: FETCH_SEASONS_REQUEST
  }
}

export const fetchSeasonsSuccess = seasons => {
  return {
    type: FETCH_SEASONS_SUCCESS,
    payload: seasons
  }
}

export const fetchSeasonsFailure = error => {
  return {
    type: FETCH_SEASONS_FAILURE,
    payload: error
  }
}

export const checkSeasons = () => {
  return {
    type: CHECK_SEASONS,
    seasons: JSON.parse(localStorage.getItem('seasons'))
  }
}
