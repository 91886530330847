import { FETCH_INDUSTRY_ANALYTICS_REQUEST, FETCH_INDUSTRY_ANALYTICS_SUCCESS, FETCH_INDUSTRY_ANALYTICS_FAILURE, CHECK_INDUSTRY_ANALYTICS } from './industryAnalyticsTypes';

const initialState = {
  loading: false,
  industryAnalytics: [],
  error: ''
}

const industryAnalyticsReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_INDUSTRY_ANALYTICS_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_INDUSTRY_ANALYTICS_SUCCESS: return {
      loading: false,
      industryAnalytics: action.industryAnalytics,
      error: ''
    }
    case FETCH_INDUSTRY_ANALYTICS_FAILURE: return {
      ...state,
      loading: false,
      error: action.payload
    }
    case CHECK_INDUSTRY_ANALYTICS: return {
      ...state,
      industryAnalytics: action.industryAnalytics
    }
    default: return state
  }
}

export default industryAnalyticsReducer;