import {
  FETCH_YEAR_END_FORMS_REQUEST,
  FETCH_YEAR_END_FORMS_SUCCESS,
  FETCH_YEAR_END_FORMS_FAILURE,
  SELECT_CURRENT_YEAR_END_FORM,
  REFRESH_YEAR_END_FORMS,
  PATCH_YEAR_END_FORM_REQUEST,
  PATCH_YEAR_END_FORM_SUCCESS,
  PATCH_YEAR_END_FORM_FAILURE,
  AMEND_YEAR_END_FORM_REQUEST,
  AMEND_YEAR_END_FORM_SUCCESS,
  AMEND_YEAR_END_FORM_FAILURE,
  CERTIFY_YEAR_END_FORM_REQUEST,
  CERTIFY_YEAR_END_FORM_SUCCESS,
  CERTIFY_YEAR_END_FORM_FAILURE,
  SET_CERTIFIED_YEAR_END_FORM,
  REFRESH_CERTIFIED_YEAR_END_FORM,
  RESET_CERTIFIED_YEAR_END_FORM,
  SET_AUG_FORM1_AMEND_REQD,
  REFRESH_AUG_FORM1_AMEND_REQD,
  RESET_AUG_FORM1_AMEND_REQD
} from './yearEndTypes';

const initialState = {
  loading: false,
  yearEndForms: [],
  initialYearEndForms: false,
  currentYearEndForm: [],
  certifiedForm: [],
  augAmendForm1Reqd: [],
  error: ''
}

const yearEndReducer = (state = initialState, action) => {
  switch(action.type) {
    case  FETCH_YEAR_END_FORMS_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_YEAR_END_FORMS_SUCCESS: return {
      ...state,
      loading: false,
      yearEndForms: action.payload,
      initialYearEndForms: true,
      currentYearEndForm: [],
      error: ''
    }
    case FETCH_YEAR_END_FORMS_FAILURE: return {
      ...state,
      loading: false,
      yearEndForms: [],
      initialYearEndForms: false,
      currentYearEndForm: [],
      error: action.payload
    }
    case SELECT_CURRENT_YEAR_END_FORM: {
      const yearEndForms = state.yearEndForms;
      //console.log('SELECT CURRENT', yearEndForms);
      let yearEndFormIndex = yearEndForms.map(yearEndForms => yearEndForms.id).indexOf(action.yearEndFormID);
      const currentYearEndForm = yearEndForms[yearEndFormIndex];
      return {
        ...state,
        currentYearEndForm: currentYearEndForm,
      }
    }
    case REFRESH_YEAR_END_FORMS: {
      const storedYearEndForms = localStorage.getItem('year_end_forms');
      if (storedYearEndForms) {
        try {
          const yearEndForms = JSON.parse(storedYearEndForms);
          if (yearEndForms.data && yearEndForms.data.length > 0) {
            return {
              ...state,
              yearEndForms: yearEndForms.data,
              initialYearEndForms: true
            }
          }
          else {
            return {
              ...state,
              yearEndForms: [],
              initialYearEndForms: false
            }
          }
        } catch (e) {}
      }
    }
    case PATCH_YEAR_END_FORM_REQUEST: return {
      ...state,
      loading: true
    }
    case PATCH_YEAR_END_FORM_SUCCESS: return {
      ...state,
      loading: false,
      error: ''
    }
    case PATCH_YEAR_END_FORM_FAILURE: return {
      ...state,
      loading: false,
      error: action.payload
    }
    case AMEND_YEAR_END_FORM_REQUEST: return {
      ...state,
      loading: true
    }
    case AMEND_YEAR_END_FORM_SUCCESS: return {
      ...state,
      loading: false,
      error: ''
    }
    case AMEND_YEAR_END_FORM_FAILURE: return {
      ...state,
      loading: false,
      error: action.payload
    }
    case CERTIFY_YEAR_END_FORM_REQUEST: return {
      ...state,
      loading: true
    }
    case CERTIFY_YEAR_END_FORM_SUCCESS: return {
      ...state,
      loading: false,
      error: ''
    }
    case CERTIFY_YEAR_END_FORM_FAILURE: return {
      ...state,
      loading: false,
      error: action.payload
    }
    case SET_CERTIFIED_YEAR_END_FORM: return {
      ...state,
      certifiedForm: action.payload
    }
    case REFRESH_CERTIFIED_YEAR_END_FORM: {
      const storedCertifiedYearEndForm = localStorage.getItem('new_year_end_form_certification');
      //console.log('REFRESH CERTIFIED YEAR END FORM', storedCertifiedYearEndForm);
      if (storedCertifiedYearEndForm) {
        try {
          const newCertificationID = storedCertifiedYearEndForm;
          if (newCertificationID.length > 0) {
            return {
              ...state,
              certifiedForm: newCertificationID
            }
          }
          else {
            return {
              ...state,
              certifiedForm: []
            }
          }
        } catch (e) {}
      }
    }
    case RESET_CERTIFIED_YEAR_END_FORM: {
      localStorage.removeItem('new_year_end_form_certification');
      return {
        ...state,
        certifiedForm: []
      }
    }
    case SET_AUG_FORM1_AMEND_REQD: return {
      ...state,
      augAmendForm1Reqd: action.payload
    }
    case REFRESH_AUG_FORM1_AMEND_REQD: {
      const storedAugForm1AmendReqd = localStorage.getItem('aug_form1_amend_reqd');
      //console.log('REFRESH CERTIFIED YEAR END FORM', storedCertifiedYearEndForm);
      if (storedAugForm1AmendReqd) {
        try {
          const newAugForm1AmendReqdID = storedAugForm1AmendReqd;
          if (newAugForm1AmendReqdID.length > 0) {
            return {
              ...state,
              augAmendForm1Reqd: newAugForm1AmendReqdID
            }
          }
          else {
            return {
              ...state,
              augAmendForm1Reqd: []
            }
          }
        } catch (e) {}
      }
    }
    case RESET_AUG_FORM1_AMEND_REQD: {
      localStorage.removeItem('aug_form1_amend_reqd');
      return {
        ...state,
        augAmendForm1Reqd: []
      }
    }
    default: return state
  }
}

export default yearEndReducer;
