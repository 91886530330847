import { FETCH_HEADLINES_REQUEST, FETCH_HEADLINES_SUCCESS, FETCH_HEADLINES_FAILURE } from './testComponentTypes';
//import axios from 'axios';
//import getHeadlinesFromAPI from '../../library/drupal/DrupalAPI';
//import DrupalAuth from '../../library/drupal/DrupalAuth';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

// thunk allows action creators to return a function (instead of an action)
// the function receives the dispatch method as its argument
// export const fetchHeadlines = () => {
//   return (dispatch) => {
//     dispatch(fetchHeadlinesRequest);
//     //axios.get('http://jsonplaceholder.typicode.com/posts')  //abstract this up to the api file.  Instead import a function from api
//     const response = getHeadlinesFromAPI();
//     console.log('Response:', response);
//       // .then(response => {
//       //   const headlines = response.data;
//       //   dispatch(fetchHeadlinesSuccess(headlines));
//       // })
//       // .catch(error => {
//       //   const errorMsg = error.message;
//       //   dispatch(fetchHeadlinesFailure(errorMsg));
//       // })
//   }
// }

export const fetchHeadlines = () => {
  //const url = '/jsonapi/node/article';
  const url = '/jsonapi/node/invoice';
  //const url = '/jsonapi/user/user?filter[anon][condition][path]=uid&filter[anon][condition][value]=0&filter[anon][condition][operator]=<>';
  const options = {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return (dispatch) => {
    dispatch(fetchHeadlinesRequest);
    auth.fetchWithAuthentication(url, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        const headlines = data;
        dispatch(fetchHeadlinesSuccess(headlines));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchHeadlinesFailure(errorMsg));
      })
  }
}

// export const fetchHeadlines = () => {
//   const url = 'http://d8-dev.n1.fishhookdev.com/jsonapi/node/article';
//   return (dispatch) => {
//     dispatch(fetchHeadlinesRequest);
//     if (localStorage.getItem("access_token")) {
//       fetch(url, {
//         method: 'GET',
//         headers: DrupalAuth.buildHeader(),
//         mode: 'cors'
//       })
//       .then(response => {
//         console.log('RESPONSE:', response);
//         if (response.status === 401) {
//           //console.log("401 Error.  Refreshing");
//           //DrupalAuth.getRefreshToken();
//           dispatch(fetchHeadlinesFailure("401"));
//           return response.statusText;
//         }
//         else {
//           return response.json();
//         }
//       })
//       .then(function(data) {
//         console.log('DATA:', data);
//         const headlines = data;
//         dispatch(fetchHeadlinesSuccess(headlines));
//       })
//       .catch(error => {
//         console.log('ERROR:', error);
//         const errorMsg = error.message;
//         dispatch(fetchHeadlinesFailure(errorMsg));
//       })
//     }
//   }
// }

export const fetchHeadlinesRequest = () => {
  return {
    type: FETCH_HEADLINES_REQUEST
  }
}

export const fetchHeadlinesSuccess = headlines => {
  return {
    type: FETCH_HEADLINES_SUCCESS,
    payload: headlines.data
  }
}

export const fetchHeadlinesFailure = error => {
  return {
    type: FETCH_HEADLINES_FAILURE,
    payload: error
  }
}

// Define our action creator (function that returns an object)
// Action creator returns an action
// An action is an object with a type property
// Convention is to create constants for action types
// Export action creator to be able to use in components
