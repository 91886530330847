import {
  FETCH_FORMS_PACKAGES_REQUEST,
  FETCH_FORMS_PACKAGES_SUCCESS,
  FETCH_FORMS_PACKAGES_FAILURE,
  FETCH_FORMS_PACKAGES_BY_SEASON_REQUEST,
  FETCH_FORMS_PACKAGES_BY_SEASON_SUCCESS,
  FETCH_FORMS_PACKAGES_BY_SEASON_FAILURE,
  RESET_FORMS_PACKAGES_BY_SEASON,
  CHECK_FORMS_PACKAGES,
  SELECT_CURRENT_FORMS_PACKAGE,
  SELECT_CURRENT_FORM,
  SELECT_PRIOR_FORMS_PACKAGE,
  REFRESH_FORMS_PACKAGES,
  PATCH_FORM_REQUEST,
  PATCH_FORM_SUCCESS,
  PATCH_FORM_FAILURE,
  AMEND_FORM_REQUEST,
  AMEND_FORM_SUCCESS,
  AMEND_FORM_FAILURE,
  CERTIFY_FORM_REQUEST,
  CERTIFY_FORM_SUCCESS,
  CERTIFY_FORM_FAILURE,
  SET_CERTIFIED_FORM,
  REFRESH_CERTIFIED_FORM,
  RESET_CERTIFIED_FORM,
  POST_PURCHASED_NON_US_SUCCESS,
  POST_EXPORT_BY_COUNTRY_SUCCESS
} from './formsPackageTypes';
import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchFormsPackagesNEW = (handlerUID, seasons) => {
  //console.log('FETCHING FORMS PACKAGES');
  return async (dispatch) => {
    dispatch(fetchFormsPackagesRequest);
    let formsPackages = [];
    for (const season of seasons) {
      const seasonFormsPackages = await fetchFormsPackagesBySeasonNEW(handlerUID, season.id);
      if (seasonFormsPackages !== null) {
        formsPackages.push(seasonFormsPackages.data);
      }
    };
    //console.log('FORMS PACKAGES', formsPackages);
    localStorage.setItem('forms_packages', JSON.stringify(formsPackages));
    dispatch(fetchFormsPackagesSuccessNEW(formsPackages));
  }
}

export const fetchFormsPackagesBySeasonNEW = async (handlerUID, seasonID) => {
  if (handlerUID !== null) {
    const path = '/jsonapi/node/reporting_forms_package';
    const filter = `filter[and-group][group][conjunction]=AND&filter[handler-filter][condition][path]=field_handler.id&filter[handler-filter][condition][value]=${handlerUID}&filter[handler-filter][condition][memberOf]=and-group&filter[season-filter][condition][path]=field_reporting_period.field_season.id&filter[season-filter][condition][value]=${seasonID}&filter[season-filter][condition][memberOf]=and-group`;
    const sort = 'sort[package-sort][path]=field_reporting_period.field_due_date&sort[package-sort][direction]=DESC';
    const include = 'include=field_reporting_period,field_form_1.field_form_1_pdf,field_form_2.field_form_2_pdf,field_form_2.field_purchased_outside_us,field_form_3.field_form_3_pdf,field_form_3.field_exports_by_country';
    const url = `${path}?${filter}&${sort}&${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const formsPackagesBySeason = data;
        return formsPackagesBySeason;
      }
      else {
        return null;
      }
    }
    catch (error) {
    }
  }
}

export const fetchFormsPackages = (handlerUID) => {
  if (handlerUID !== null) {
    const path = '/jsonapi/node/reporting_forms_package';
    const filter = `filter[field_handler.id]=${handlerUID}`;
    const include = 'include=field_reporting_period,field_form_1.field_form_1_pdf,field_form_2.field_form_2_pdf,field_form_2.field_purchased_outside_us,field_form_3.field_form_3_pdf,field_form_3.field_exports_by_country';
    const url = `${path}?${filter}&${include}`;
    //const url = `/jsonapi/node/reporting_forms_package?filter[field_handler.id]=${handlerUID}&include=field_reporting_period,field_form_1.field_form_1_pdf,field_form_2.field_form_2_pdf,field_form_2.field_purchased_outside_us,field_form_3.field_form_3_pdf,field_form_3.field_exports_by_country`;
    const options = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return async (dispatch) => {
      dispatch(fetchFormsPackagesRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          const data = await response.json();
          const formsPackages = data;
          //localStorage.setItem('forms_packages', JSON.stringify(formsPackages));
          dispatch(fetchFormsPackagesSuccess(formsPackages));
        }
        else {
          throw new Error(`message: ${response.status}`);
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(fetchFormsPackagesFailure(errorMsg));
      }
    }
  }
}

export const fetchFormsPackagesBySeason = (handlerUID, seasonID) => {
  if (handlerUID !== null) {
    const path = '/jsonapi/node/reporting_forms_package';
    const filter = `filter[and-group][group][conjunction]=AND&filter[handler-filter][condition][path]=field_handler.id&filter[handler-filter][condition][value]=${handlerUID}&filter[handler-filter][condition][memberOf]=and-group&filter[season-filter][condition][path]=field_reporting_period.field_season.id&filter[season-filter][condition][value]=${seasonID}&filter[season-filter][condition][memberOf]=and-group`;
    const sort = 'sort[package-sort][path]=field_reporting_period.field_due_date&sort[package-sort][direction]=DESC';
    const include = 'include=field_reporting_period,field_form_1.field_form_1_pdf,field_form_2.field_form_2_pdf,field_form_2.field_purchased_outside_us,field_form_3.field_form_3_pdf,field_form_3.field_exports_by_country';
    const url = `${path}?${filter}&${sort}&${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return async (dispatch) => {
      dispatch(fetchFormsPackagesBySeasonRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          const data = await response.json();
          const formsPackagesBySeason = data;
          //localStorage.setItem('forms_packages', JSON.stringify(formsPackages));
          dispatch(fetchFormsPackagesBySeasonSuccess(formsPackagesBySeason));
        }
        else {
          throw new Error(`message: ${response.status}`);
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(fetchFormsPackagesBySeasonFailure(errorMsg));
      }
    }
  }
}

export const patchForm1 = (formID, data) => {
  if (formID !== null) {
    const url = `/jsonapi/node/apc_form_1/${formID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(patchFormRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(patchFormSuccess(JSON.stringify(response)));
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          let seasons = null;
          try {
            seasons = JSON.parse(localStorage.getItem('seasons'));
          }
          catch {
            //throw new error
          }
          await dispatch(fetchFormsPackagesNEW(handlerUID, seasons));
          history.push(`/dashboard/formCertification/${formID}`);
        }
        else {
          dispatch(patchFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(patchFormFailure(errorMsg));
      }
    }
  }
}

export const amendForm1 = (formID, packageID, data) => {
  if (formID !== null) {
    const url = `/jsonapi/node/apc_form_1`;
    const options = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(amendFormRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(amendFormSuccess(JSON.stringify(response)));
          const data = await response.json();
          const revisedFormID = data.data.id;
          //console.log('REVISED FORM ID', revisedFormID);
          await dispatch(addForm1ToPackage(revisedFormID, packageID));
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          let seasons = null;
          try {
            seasons = JSON.parse(localStorage.getItem('seasons'));
          }
          catch {
            //throw new error
          }
          await dispatch(fetchFormsPackagesNEW(handlerUID, seasons));
          history.push(`/dashboard/formAmendCertification/${revisedFormID}`);
        }
        else {
          dispatch(amendFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(amendFormFailure(errorMsg));
      }
    }
  }
}

export const addForm1ToPackage = (formID, packageID) => {
  //console.log('FORM TO ADD', formID);
  //console.log('PACKAGE TO ADD TO', packageID);
  if (formID !== null && packageID !== null) {
    let data = {};
    data = {
      "data": [
        {
          "type": "node--apc_form_1", // Not in documentation but required.
          "id": formID
        }
      ]
    };
    const url = `/jsonapi/node/reporting_forms_package/${packageID}/relationships/field_form_1`;  //POST to a relationship resource will ADD the new node to the multi-value field.  Need to send as array.  PATCH will REPLACE existing field data.  
    //console.log('ADD TO PKG DATA', data);
    //console.log('ADD TO PKG URL', url);
    const options = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          //console.log('ADD TO PACKAGE OK');
        }
        else {
          dispatch(amendFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
      }
    }
  }
}

export const patchForm2 = (formID, data) => {
  if (formID !== null) {
    const url = `/jsonapi/node/apc_form_2/${formID}`;
    //const url = `/jsonapi/node/apc_form_2/${formID}/relationships/field_purchased_outside_us`
    const options = {
      method: 'PATCH',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(patchFormRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(patchFormSuccess(JSON.stringify(response)));
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          let seasons = null;
          try {
            seasons = JSON.parse(localStorage.getItem('seasons'));
          }
          catch {
            //throw new error
          }
          await dispatch(fetchFormsPackagesNEW(handlerUID, seasons));
          history.push(`/dashboard/formCertification/${formID}`);
        }
        else {
          dispatch(patchFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(patchFormFailure(errorMsg));
      }
    }
  }
}

export const amendForm2 = (formID, packageID, data) => {
  if (formID !== null) {
    const url = `/jsonapi/node/apc_form_2`;
    //const url = `/jsonapi/node/apc_form_2/${formID}/relationships/field_purchased_outside_us`
    const options = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(amendFormRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(amendFormSuccess(JSON.stringify(response)));
          const data = await response.json();
          const revisedFormID = data.data.id;
          //console.log('REVISED FORM ID', revisedFormID);
          await dispatch(addForm2ToPackage(revisedFormID, packageID));
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          let seasons = null;
          try {
            seasons = JSON.parse(localStorage.getItem('seasons'));
          }
          catch {
            //throw new error
          }
          await dispatch(fetchFormsPackagesNEW(handlerUID, seasons));
          history.push(`/dashboard/formAmendCertification/${revisedFormID}`);
        }
        else {
          dispatch(amendFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(amendFormFailure(errorMsg));
      }
    }
  }
}


export const addForm2ToPackage = (formID, packageID) => {
  //console.log('FORM TO ADD', formID);
  //console.log('PACKAGE TO ADD TO', packageID);
  if (formID !== null && packageID !== null) {
    let data = {};
    data = {
      "data": [
        {
          "type": "node--apc_form_2", // Not in documentation but required.
          "id": formID
        }
      ]
    };
    const url = `/jsonapi/node/reporting_forms_package/${packageID}/relationships/field_form_2`;  //POST to a relationship resource will ADD the new node to the multi-value field.  Need to send as array.  PATCH will REPLACE existing field data.  
    //console.log('ADD TO PKG DATA', data);
    //console.log('ADD TO PKG URL', url);
    const options = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          //console.log('ADD TO PACKAGE OK');
        }
        else {
          dispatch(amendFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
      }
    }
  }
}


export const patchForm3 = (formID, data) => {
  if (formID !== null) {
    const url = `/jsonapi/node/apc_form_3/${formID}`;
    //const url = `/jsonapi/node/apc_form_2/${formID}/relationships/field_purchased_outside_us`
    const options = {
      method: 'PATCH',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(patchFormRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(patchFormSuccess(JSON.stringify(response)));
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          let seasons = null;
          try {
            seasons = JSON.parse(localStorage.getItem('seasons'));
          }
          catch {
            //throw new error
          }
          await dispatch(fetchFormsPackagesNEW(handlerUID, seasons));
          history.push(`/dashboard/formCertification/${formID}`);
        }
        else {
          dispatch(patchFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(patchFormFailure(errorMsg));
      }
    }
  }
}


export const amendForm3 = (formID, packageID, data) => {
  if (formID !== null) {
    const url = `/jsonapi/node/apc_form_3`;
    //const url = `/jsonapi/node/apc_form_2/${formID}/relationships/field_purchased_outside_us`
    const options = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(amendFormRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(amendFormSuccess(JSON.stringify(response)));
          const data = await response.json();
          const revisedFormID = data.data.id;
          //console.log('REVISED FORM ID', revisedFormID);
          await dispatch(addForm3ToPackage(revisedFormID, packageID));
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          let seasons = null;
          try {
            seasons = JSON.parse(localStorage.getItem('seasons'));
          }
          catch {
            //throw new error
          }
          await dispatch(fetchFormsPackagesNEW(handlerUID, seasons));
          history.push(`/dashboard/formAmendCertification/${revisedFormID}`);
        }
        else {
          dispatch(patchFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(patchFormFailure(errorMsg));
      }
    }
  }
}


export const addForm3ToPackage = (formID, packageID) => {
  //console.log('FORM TO ADD', formID);
  //console.log('PACKAGE TO ADD TO', packageID);
  if (formID !== null && packageID !== null) {
    let data = {};
    data = {
      "data": [
        {
          "type": "node--apc_form_3", // Not in documentation but required.
          "id": formID
        }
      ]
    };
    const url = `/jsonapi/node/reporting_forms_package/${packageID}/relationships/field_form_3`;  //POST to a relationship resource will ADD the new node to the multi-value field.  Need to send as array.  PATCH will REPLACE existing field data.  
    //console.log('ADD TO PKG DATA', data);
    //console.log('ADD TO PKG URL', url);
    const options = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          //console.log('ADD TO PACKAGE OK');
        }
        else {
          dispatch(amendFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
      }
    }
  }
}


export const postPurchasedNonUS = (data) => {
  const url = `/jsonapi/node/pecans_purchased_non_us`;
  const options = {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data)
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        dispatch(postPurchasedNonUSSuccess(data.data));
      }
      else {
        throw new Error(`message: ${response.status}`);
      }
    }
    catch (error) {
      const errorMsg = error.message;
      dispatch(patchFormFailure(errorMsg));
    }
  }
}


export const postExportByCountry = (data) => {
  const url = `/jsonapi/node/export_by_country`;
  const options = {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data)
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        dispatch(postExportByCountrySuccess(data.data));
      }
      else {
        throw new Error(`message: ${response.status}`);
      }
    }
    catch (error) {
      const errorMsg = error.message;
      dispatch(patchFormFailure(errorMsg));
    }
  }
}

// export const fetchFormsPackages = (handlerUID) => {
//  if (handlerUID !== null) {
//    //const url = '/jsonapi/node/invoice?include=field_reporting_period';
//    const url = `/jsonapi/node/reporting_forms_package?filter[field_handler.id]=${handlerUID}&include=field_reporting_period,field_form_1.field_form_1_pdf,field_form_2,field_form_3`;
//    const options = {
//      method: 'GET',
//      headers: new Headers({
//        'Accept': 'application/vnd.api+json',
//        'Content-Type': 'application/vnd.api+json',
//      }),
//      mode: 'cors',
//    };
//    return (dispatch) => {
//      dispatch(fetchFormsPackagesRequest);
//      auth.fetchWithAuthentication(url, options)
//        .then(response => {
//          return response.json();
//        })
//        .then(data => {
//          console.log('FETCH FROM SERVER SUCCESS', data);
//          const formsPackages = data;
//          localStorage.setItem('forms_packages', JSON.stringify(formsPackages));
//          dispatch(fetchFormsPackagesSuccess(formsPackages));
//        })
//        .catch(error => {
//          const errorMsg = error.message;
//          dispatch(fetchFormsPackagesFailure(errorMsg));
//        })
//    }
//  }
// }

// export const patchForm1 = (formID, data) => {
//  console.log('PATCH FORM 1');
//   if (formID !== null) {
//    const url = `/jsonapi/node/apc_form_1/${formID}`;
//    const options = {
//      method: 'PATCH',
//      headers: new Headers({
//        'Accept': 'application/vnd.api+json',
//        'Content-Type': 'application/vnd.api+json',
//      }),
//      mode: 'cors',
//      body: JSON.stringify(data)
//    };
//    return (dispatch) => {
//      dispatch(patchFormRequest);
//      auth.fetchWithAuthentication(url, options)
//        .then(response => {
//          console.log('PATCH RESPONSE', response);
//          return response.json();
//        })
//        .then(data => {
//          console.log('PATCH SUCCESS DATA', data);
//          //const formsPackages = data;
//          //localStorage.setItem('forms_packages', JSON.stringify(formsPackages));
//          //dispatch(patchFormSuccess(formsPackages));
//        })
//        .catch(error => {
//          console.log('PATCH ERROR');
//          const errorMsg = error.message;
//          dispatch(patchFormFailure(errorMsg));
//        })
//    }
//   }
// }

export const certifyForm = (formID, formType, data) => {
  if (formID !== null && formType !== null) {
    const url = `/jsonapi/node/${formType}/${formID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(certifyFormRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          await dispatch(certifyFormSuccess(JSON.stringify(response)));
          localStorage.setItem('new_form_certification', formID);
          await dispatch(setNewCertification(formID));
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          let seasons = null;
          try {
            seasons = JSON.parse(localStorage.getItem('seasons'));
          }
          catch {
            //throw new error
          }
          await dispatch(fetchFormsPackagesNEW(handlerUID, seasons));
          history.push(`/dashboard`);
        }
        else {
          dispatch(certifyFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(certifyFormFailure(errorMsg));
      }
    }
  }
}

export const fetchFormsPackagesRequest = () => {
  return {
    type: FETCH_FORMS_PACKAGES_REQUEST
  }
}

export const fetchFormsPackagesSuccess = formsPackages => {
  return {
    type: FETCH_FORMS_PACKAGES_SUCCESS,
    payload: formsPackages.data
  }
}

export const fetchFormsPackagesSuccessNEW = formsPackages => {
  return {
    type: FETCH_FORMS_PACKAGES_SUCCESS,
    payload: formsPackages
  }
}

export const fetchFormsPackagesFailure = error => {
  return {
    type: FETCH_FORMS_PACKAGES_FAILURE,
    payload: error
  }
}

export const fetchFormsPackagesBySeasonRequest = () => {
  return {
    type: FETCH_FORMS_PACKAGES_BY_SEASON_REQUEST
  }
}

export const fetchFormsPackagesBySeasonSuccess = formsPackagesBySeason => {
  return {
    type: FETCH_FORMS_PACKAGES_BY_SEASON_SUCCESS,
    payload: formsPackagesBySeason.data
  }
}

export const fetchFormsPackagesBySeasonFailure = error => {
  return {
    type: FETCH_FORMS_PACKAGES_BY_SEASON_FAILURE,
    payload: error
  }
}

export const resetFormsPackagesBySeason = () => {
  return {
    type: RESET_FORMS_PACKAGES_BY_SEASON
  }
}

export const selectCurrentFormsPackage = (formID) => {
  return {
    type: SELECT_CURRENT_FORMS_PACKAGE,
    formID: formID
  }
}

export const selectCurrentForm = (formID) => {
  return {
    type: SELECT_CURRENT_FORM,
    formID: formID
  }
}

export const selectPriorFormsPackage = (formID) => {
  return {
    type: SELECT_PRIOR_FORMS_PACKAGE,
    formID: formID
  }
}

export const refreshFormsPackages = () => {
  return {
    type: REFRESH_FORMS_PACKAGES
  }
}

export const patchFormRequest = () => {
  return {
    type: PATCH_FORM_REQUEST
  }
}

export const patchFormSuccess = patchResult => {
  return {
    type: PATCH_FORM_SUCCESS,
    payload: patchResult
  }
}

export const patchFormFailure = error => {
  return {
    type: PATCH_FORM_FAILURE,
    payload: error
  }
}

export const amendFormRequest = () => {
  return {
    type: AMEND_FORM_REQUEST
  }
}

export const amendFormSuccess = patchResult => {
  return {
    type: AMEND_FORM_SUCCESS,
    payload: patchResult
  }
}

export const amendFormFailure = error => {
  return {
    type: AMEND_FORM_FAILURE,
    payload: error
  }
}

export const certifyFormRequest = () => {
  return {
    type: CERTIFY_FORM_REQUEST
  }
}

export const certifyFormSuccess = certifyResult => {
  return {
    type: CERTIFY_FORM_SUCCESS,
    payload: certifyResult
  }
}

export const certifyFormFailure = error => {
  return {
    type: CERTIFY_FORM_FAILURE,
    payload: error
  }
}

export const setNewCertification = formID => {
  return {
    type: SET_CERTIFIED_FORM,
    payload: formID
  }
}

export const refreshNewCertification = () => {
  return {
    type: REFRESH_CERTIFIED_FORM
  }
}

export const resetNewCertification = () => {
  return {
    type: RESET_CERTIFIED_FORM
  }
}

export const postPurchasedNonUSSuccess = postResult => {
  return {
    type: POST_PURCHASED_NON_US_SUCCESS,
    payload: postResult
  }
}

export const postExportByCountrySuccess = postResult => {
  return {
    type: POST_EXPORT_BY_COUNTRY_SUCCESS,
    payload: postResult
  }
}

export const checkFormsPackages = () => {
  return {
    type: CHECK_FORMS_PACKAGES,
    formsPackages: JSON.parse(localStorage.getItem('forms_packages'))
  }
}
