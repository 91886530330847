export const FETCH_FORMS_PACKAGES_REQUEST = 'FETCH_FORMS_PACKAGES_REQUEST';
export const FETCH_FORMS_PACKAGES_SUCCESS = 'FETCH_FORMS_PACKAGES_SUCCESS';
export const FETCH_FORMS_PACKAGES_FAILURE = 'FETCH_FORMS_PACKAGES_FAILURE';
export const FETCH_FORMS_PACKAGES_BY_SEASON_REQUEST = 'FETCH_FORMS_PACKAGES_BY_SEASON_REQUEST';
export const FETCH_FORMS_PACKAGES_BY_SEASON_SUCCESS = 'FETCH_FORMS_PACKAGES_BY_SEASON_SUCCESS';
export const FETCH_FORMS_PACKAGES_BY_SEASON_FAILURE = 'FETCH_FORMS_PACKAGES_BY_SEASON_FAILURE';
export const CHECK_FORMS_PACKAGES = 'CHECK_FORMS_PACKAGES';
export const RESET_FORMS_PACKAGES_BY_SEASON = 'RESET_FORMS_PACKAGES_BY_SEASON';
export const SELECT_CURRENT_FORMS_PACKAGE = 'SELECT_CURRENT_FORMS_PACKAGE';
export const SELECT_CURRENT_FORM = 'SELECT_CURRENT_FORM';
export const SELECT_PRIOR_FORMS_PACKAGE = 'SELECT_PRIOR_FORMS_PACKAGE';
export const REFRESH_FORMS_PACKAGES = 'REFRESH_FORMS_PACKAGES';
export const PATCH_FORM_REQUEST = 'PATCH_FORM_REQUEST';
export const PATCH_FORM_SUCCESS = 'PATCH_FORM_SUCCESS';
export const PATCH_FORM_FAILURE = 'PATCH_FORM_FAILURE';
export const AMEND_FORM_REQUEST = 'PATCH_FORM_REQUEST';
export const AMEND_FORM_SUCCESS = 'PATCH_FORM_SUCCESS';
export const AMEND_FORM_FAILURE = 'PATCH_FORM_FAILURE';
export const CERTIFY_FORM_REQUEST = 'CERTIFY_FORM_REQUEST';
export const CERTIFY_FORM_SUCCESS = 'CERTIFY_FORM_SUCCESS';
export const CERTIFY_FORM_FAILURE = 'CERTIFY_FORM_FAILURE';
export const SET_CERTIFIED_FORM = 'SET_CERTIFIED_FORM';
export const REFRESH_CERTIFIED_FORM = 'REFRESH_CERTIFIED_FORM';
export const RESET_CERTIFIED_FORM = 'RESET_CERTIFIED_FORM';
export const POST_PURCHASED_NON_US_SUCCESS = 'POST_PURCHASED_NON_US_SUCCESS';
export const POST_EXPORT_BY_COUNTRY_SUCCESS = 'POST_EXPORT_BY_COUNTRY_SUCCESS';