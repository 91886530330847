import { FETCH_HANDLER_PROFILE_REQUEST, FETCH_HANDLER_PROFILE_SUCCESS, FETCH_HANDLER_PROFILE_FAILURE, CHECK_HANDLER_PROFILE } from './handlerTypes';

const initialState = {
  loading: false,
  handlerName: null,
  handlerNumber: null,
  handlerStatus: null,
  handlerProfile: [],
  error: ''
}

const handlerReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_HANDLER_PROFILE_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_HANDLER_PROFILE_SUCCESS: return {
      loading: false,
      handlerName: action.handlerName,
      handlerNumber: action.handlerNumber,
      handlerStatus: action.handlerStatus,
      handlerProfile: action.handlerProfile,
      error: ''
    }
    case FETCH_HANDLER_PROFILE_FAILURE: return {
      ...state,
      loading: false,
      error: action.payload
    }
    case CHECK_HANDLER_PROFILE: return {
      ...state,
      handlerName: action.handlerName,
      handlerNumber: action.handlerNumber,
      handlerStatus: action.handlerStatus,
      handlerProfile: action.handlerProfile
    }
    default: return state
  }
}

export default handlerReducer;