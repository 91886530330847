import {
  FETCH_HANDLERS_REQUEST,
  FETCH_HANDLERS_SUCCESS,
  FETCH_HANDLERS_FAILURE,
  CHANGE_HANDLER
} from './handlerDirectoryTypes';

import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchHandlers = () => {
  const url = `/jsonapi/node/handler?filter[visible-filter][condition][path]=field_show_in_handler_directory&filter[visible-filter][condition][value]=1`;
  //const url = `/jsonapi/user/user?filter[anon][condition][path]=uid&filter[anon][condition][value]=0&filter[anon][condition][operator]=<>`;
  //const url = `/jsonapi/user/user?filter[and-group][group][conjunction]=AND&filter[handler-filter][condition][path]=field_user_handler_company.id&filter[handler-filter][condition][value]=${handlerUID}&filter[handler-filter][condition][memberOf]=and-group&filter[visible-filter][condition][path]=field_show_in_directory&filter[visible-filter][condition][value]=1&filter[visible-filter][condition][memberOf]=and-group`;
  const options = {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return (dispatch) => {
    dispatch(fetchHandlersRequest);
    auth.fetchWithAuthentication(url, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        const handlers = data;
        dispatch(fetchHandlersSuccess(handlers));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchHandlersFailure(errorMsg));
      })
  }
}

export const fetchHandlersRequest = () => {
  return {
    type: FETCH_HANDLERS_REQUEST
  }
}

export const fetchHandlersSuccess = handlers => {
  return {
    type: FETCH_HANDLERS_SUCCESS,
    payload: handlers.data
  }
}

export const fetchHandlersFailure = error => {
  return {
    type: FETCH_HANDLERS_FAILURE,
    payload: error
  }
}

export const changeHandler = id => {
  return {
    type: CHANGE_HANDLER,
    id: id
  }
}

function ascendingSort(handler1, handler2) {
  const name1 = handler1.title ? handler1.title.toUpperCase() : '~';
  const name2 = handler2.title ? handler2.title.toUpperCase() : '~';
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}
