import {
  FETCH_HANDLER_PROFILE_REQUEST,
  FETCH_HANDLER_PROFILE_SUCCESS,
  FETCH_HANDLER_PROFILE_FAILURE,
  CHECK_HANDLER_PROFILE,
  PATCH_HANDLER_REQUEST,
  PATCH_HANDLER_SUCCESS,
  PATCH_HANDLER_FAILURE
} from './handlerTypes';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchHandlerProfile = (handlerUID) => {
  if (handlerUID !== null) {
    //console.log("FETCHING HANDLER PROFILE");
    //const url = `/jsonapi/node/handler/${handlerUID}`;
    const url = `/jsonapi/node/handler/${handlerUID}?include=field_handler_analytics`;
    //console.log('URL', url);
    const options = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return async (dispatch) => {
      dispatch(fetchHandlerProfileRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options)
        if (response.ok) {
          //console.log("FETCH HANDLER PROFILE RESPONSE OK");
          const data = await response.json();
          const handlerProfile = data.data;
          //console.log("HANDLER PROFILE DATA", handlerProfile);
          // const handlerName = handlerProfile['attributes']['title'];
          // const handlerNumber = handlerProfile['attributes']['field_handler_number'];
          // const handlerStatus = handlerProfile['attributes']['field_handler_status'];
          const handlerName = handlerProfile['title'];
          const handlerNumber = handlerProfile['field_handler_number'];
          const handlerStatus = handlerProfile['field_handler_status'];
          //const handlerPacketConfirm = handlerProfile['field_2020_packet_confirm'];
          localStorage.setItem('handler_name', handlerName);
          localStorage.setItem('handler_number', handlerNumber);
          localStorage.setItem('handler_status', handlerStatus);
          localStorage.setItem('handler_profile', JSON.stringify(handlerProfile));
          //localStorage.setItem('handler_packet_confirm', handlerPacketConfirm);
          await dispatch(fetchHandlerProfileSuccess(handlerName, handlerNumber, handlerStatus, handlerProfile));
          //console.log("HANDLER PROFILE FETCH COMPLETE");
        }
        else {
          throw new Error(`message: ${response.status}`);
        }
      }
      catch (error) {
        //console.log("HANDLER PROFILE FETCH FAILURE");
        const errorMsg = error.message;
        dispatch(fetchHandlerProfileFailure(errorMsg));
      }
    }
  }
}

export const setHandlerPacketConfirm = (handlerUID, data) => {
  //console.log("SETTING HANDLER PACKET CONFIRM");
  const url = `/jsonapi/node/handler/${handlerUID}`;
  const options = {
    method: 'PATCH',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data)
  };
  return async (dispatch) => {
    dispatch(patchHandlerRequest);
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        //console.log("HANDLER PATCH RESPONSE OK");
        dispatch(patchHandlerSuccess(JSON.stringify(response)));
        let handlerUID = null;
        try {
          handlerUID = localStorage.getItem('handler_uid');
        }
        catch {
          //throw new Error()
        }
        await dispatch(fetchHandlerProfile(handlerUID));
      }
      else {
        //console.log("HANDLER PATCH FAILURE1");
        dispatch(patchHandlerFailure("An error has occured."));
      }
    }
    catch (error) {
      //console.log("HANDLER PATCH FAILURE2");
      const errorMsg = error.message;
      dispatch(patchHandlerFailure(errorMsg));
    }
  }
}

// export const fetchUserProfile = (username) => {
// 	console.log("FETCHING USER PROFILE");
//   const url = `/jsonapi/user/user?filter[name][value]=${username}`;
//   console.log(url);
//   const options = {
//     method: 'GET',
//     headers: new Headers({
//       'Accept': 'application/vnd.api+json',
//       'Content-Type': 'application/vnd.api+json',
//     }),
//     mode: 'cors',
//   };
//   return (dispatch) => {
//     dispatch(fetchUserProfileRequest);
//     auth.fetchWithAuthentication(url, options)
//       .then(response => {
//         return response.json();
//       })
//       .then(data => {
//         const userProfile = data.data;
//         const userID = userProfile[0]['id'];
//         const handlerNumber = userProfile[0]['relationships']['field_user_handler_company']['data']['id'];
//         localStorage.setItem('username', username);
//         localStorage.setItem('user_id', userID);
//         localStorage.setItem('handler_id', handlerNumber);
//         dispatch(fetchUserProfileSuccess(username, userID, handlerNumber));
//       })
//       .catch(error => {
//         const errorMsg = error.message;
//         dispatch(fetchUserProfileFailure(errorMsg));
//       })
//   }
// }

export const fetchHandlerProfileRequest = () => {
  //console.log("FETCH HANDLER PROFILE REQUEST");
  return {
    type: FETCH_HANDLER_PROFILE_REQUEST
  }
}

export const fetchHandlerProfileSuccess = (handlerName, handlerNumber, handlerStatus, handlerProfile) => {
  //console.log("FETCH HANDLER PROFILE SUCCESS");
  return {
    type: FETCH_HANDLER_PROFILE_SUCCESS,
    handlerName: handlerName,
    handlerNumber: handlerNumber,
    handlerStatus: handlerStatus,
    handlerProfile: handlerProfile
  }
}

export const fetchHandlerProfileFailure = error => {
  return {
    type: FETCH_HANDLER_PROFILE_FAILURE,
    payload: error
  }
}

export const checkHandlerProfile = () => {
  return {
    type: CHECK_HANDLER_PROFILE,
    handlerName: localStorage.getItem('handler_name'),
    handlerNumber: localStorage.getItem('handler_number'),
    handlerStatus: localStorage.getItem('handler_status'),
    handlerProfile: JSON.parse(localStorage.getItem('handler_profile'))
  }
}

export const patchHandlerRequest = () => {
  return {
    type: PATCH_HANDLER_REQUEST
  }
}

export const patchHandlerSuccess = () => {
  //console.log("PATCH HANDLER SUCCESS");
  return {
    type: PATCH_HANDLER_SUCCESS
  }
}

export const patchHandlerFailure = () => {
  return {
    type: PATCH_HANDLER_FAILURE
  }
}


