import { store } from './store';
import authActions from '@iso/redux/auth/actions';
import { checkUserProfile, checkHandlerProfile, checkSeasons, checkFormsPackages, checkAutoLogout, checkIndustryAnalytics } from './';

export default () =>
  new Promise(() => {
    store.dispatch(authActions.checkAuthorization());
  }); 

  new Promise(() => {
    store.dispatch(checkAutoLogout());
  });

  new Promise(() => {
    store.dispatch(checkUserProfile());
  });

  new Promise(() => {
    store.dispatch(checkHandlerProfile());
  });

  new Promise(() => {
    store.dispatch(checkSeasons());
  });

  new Promise(() => {
    store.dispatch(checkFormsPackages());
  });

 new Promise(() => {
   store.dispatch(checkIndustryAnalytics());
 });
