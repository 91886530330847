import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from './loginTypes';
import history from '@iso/lib/helpers/history';
import appAction from '@iso/redux/app/actions';
import { fetchUserProfile, fetchHandlerProfile, fetchSeasons, fetchFormsPackagesNEW, fetchIndustryAnalytics } from '../../redux';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';
import { getToken } from '@iso/lib/helpers/utility';

const auth = getAuthClient();
const { clearMenu } = appAction;

export const login = (username = null, password = null) => {
  return async (dispatch) => {
    dispatch(loginRequest);
    try {
      const response = await auth.login(username, password);
      if (response) {
        dispatch(loginSuccess(JSON.stringify(response), username));
        localStorage.setItem('id_token', JSON.stringify(response));  //necessary for app login status
        await dispatch(fetchUserProfile(username));
        let handlerUID = null;
        try {
          handlerUID = localStorage.getItem('handler_uid');
        }
        catch {
          //throw new Error()
        }
        await dispatch(fetchHandlerProfile(handlerUID));
        await dispatch(fetchSeasons());
        let seasons = null;
        try {
          seasons = JSON.parse(localStorage.getItem('seasons'));
        }
        catch {
          //throw new error
        }
        if (seasons !== null) {
          await dispatch(fetchFormsPackagesNEW(handlerUID, seasons));
        }
        await dispatch(fetchIndustryAnalytics());
        await dispatch(clearMenu());
        history.replace('/dashboard');
      }
      else {
        dispatch(loginFailure("Invalid Credentials"));
      }
    }
    catch (error) {
      const errorMsg = error.message;
      dispatch(loginFailure(errorMsg));
    }
  }
}

export const logout = () => {
  return (dispatch) => {
    auth.logout();
    localStorage.removeItem('username');
    localStorage.removeItem('id_token');  //necessary for app login status
    localStorage.removeItem('user_uid');
    localStorage.removeItem('user_firstname');
    localStorage.removeItem('handler_uid');
    localStorage.removeItem('handler_name');
    localStorage.removeItem('handler_number');
    localStorage.removeItem('handler_status');
    localStorage.removeItem('handler_profile');
    localStorage.removeItem('invoices');
    localStorage.removeItem('forms_packages');
    localStorage.removeItem('seasons');
    localStorage.removeItem('auto_logout_datetime');
    localStorage.removeItem('industry_analytics');
    localStorage.removeItem('year_end_forms');
    localStorage.removeItem('new_year_end_form_certification');
    localStorage.removeItem('new_form_certification');
    localStorage.removeItem('ih_transfers');
    localStorage.removeItem('aug_form1_amend_reqd');

    dispatch(clearMenu());
    history.replace('/');
  }
}

export const checkAutoLogout = () => {
  return (dispatch) => {
    //console.log("Checking auto logout");
    const token = getToken().get('idToken');
    if (token) {
      let autoLogoutDate = null;
      try {
        //console.log("Looking for autologout datetime");
        autoLogoutDate = localStorage.getItem('auto_logout_datetime');
      }
      catch {
        //throw new Error()
        //console.log("Autologout datetime error");
      }
      if (autoLogoutDate && autoLogoutDate > Date.now()/1000) {
        //stay logged in
        //console.log("Autologout date found: ", autoLogoutDate);
      }
      else {
        //console.log("LOGGING OUT!");
        dispatch(logout());
      }
    }
    else {
      console.log("No token found.  Autologout not happening");
    }
  }
}

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  }
}

export const loginSuccess = (loginData) => {
  return {
    type: LOGIN_SUCCESS,
    payload: loginData
  }
}

export const loginFailure = error => {
  return {
    type: LOGIN_FAILURE,
    payload: error
  }
}
