import { FETCH_INVOICES_REQUEST, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_FAILURE, SELECT_CURRENT_INVOICE, REFRESH_INVOICES } from './invoiceTypes';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchInvoices = (handlerUID) => {
	if (handlerUID !== null) {
	  //const url = '/jsonapi/node/invoice?include=field_reporting_period';
	  const url = `/jsonapi/node/invoice?filter[field_handler.id]=${handlerUID}&include=field_reporting_period,field_handler,field_form_1.field_form_1_pdf`;
	  const options = {
	    method: 'GET',
	    headers: new Headers({
	      'Accept': 'application/vnd.api+json',
	      'Content-Type': 'application/vnd.api+json',
	    }),
	    mode: 'cors',
	  };
	  return (dispatch) => {
	    dispatch(fetchInvoicesRequest);
	    auth.fetchWithAuthentication(url, options)
	      .then(response => {
	        return response.json();
	      })
	      .then(data => {
	        const invoices = data;
	        localStorage.setItem('invoices', JSON.stringify(invoices));
	        dispatch(fetchInvoicesSuccess(invoices));
	      })
	      .catch(error => {
	        const errorMsg = error.message;
	        dispatch(fetchInvoicesFailure(errorMsg));
	      })
	  }
	}
}

export const fetchInvoicesRequest = () => {
  return {
    type: FETCH_INVOICES_REQUEST
  }
}

export const fetchInvoicesSuccess = invoices => {
  return {
    type: FETCH_INVOICES_SUCCESS,
    payload: invoices.data
  }
}

export const fetchInvoicesFailure = error => {
  return {
    type: FETCH_INVOICES_FAILURE,
    payload: error
  }
}

export const selectCurrentInvoice = (invoiceID) => {
	return {
		type: SELECT_CURRENT_INVOICE,
		invoiceID: invoiceID
	}
}

export const refreshInvoices = () => {
	return {
		type: REFRESH_INVOICES
	}
}