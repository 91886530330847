import {
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  CHANGE_CONTACT
} from './contactsTypes';

import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchContacts = (handlerUID) => {
  if (handlerUID !== null) {
    //console.log("HANDLER UID", handlerUID);
    // const url = `/jsonapi/node/apc_form_4?
    //   filter[and-group][group][conjunction]=AND
    //   &filter[or-group][group][conjunction]=OR
    //   &filter[or-group][group][memberOf]=and-group
    //   &filter[season-filter][condition][path]=field_season.id
    //   &filter[season-filter][condition][value]=${seasonUID}
    //   &filter[season-filter][condition][memberOf]=and-group
    //   &filter[activity-filter][condition][path]=field_form_activity
    //   &filter[activity-filter][condition][value]=active
    //   &filter[activity-filter][condition][memberOf]=and-group
    //   &filter[rec-handler-filter][condition][path]=field_receiving_handler.id
    //   &filter[rec-handler-filter][condition][value]=${handlerUID}
    //   &filter[rec-handler-filter][condition][memberOf]=or-group
    //   &filter[transfer-handler-filter][condition][path]=field_transferring_handler.id
    //   &filter[transfer-handler-filter][condition][value]=${handlerUID}
    //   &filter[transfer-handler-filter][condition][memberOf]=or-group
    //   &include=field_form_4_pdf,field_receiving_handler,field_transferring_handler
    // `;
    //const url = `/jsonapi/user/user?filter[anon][condition][path]=uid&filter[anon][condition][value]=0&filter[anon][condition][operator]=<>`;
    const url = `/jsonapi/user/user?filter[and-group][group][conjunction]=AND&filter[handler-filter][condition][path]=field_user_handler_company.id&filter[handler-filter][condition][value]=${handlerUID}&filter[handler-filter][condition][memberOf]=and-group&filter[visible-filter][condition][path]=field_show_in_directory&filter[visible-filter][condition][value]=1&filter[visible-filter][condition][memberOf]=and-group`;
    const options = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return (dispatch) => {
      dispatch(fetchContactsRequest);
      auth.fetchWithAuthentication(url, options)
        .then(response => {
          return response.json();
        })
        .then(data => {
          const contacts = data;
          dispatch(fetchContactsSuccess(contacts));
        })
        .catch(error => {
          const errorMsg = error.message;
          dispatch(fetchContactsFailure(errorMsg));
        })
    }
  }
}

export const fetchContactsRequest = () => {
  return {
    type: FETCH_CONTACTS_REQUEST
  }
}

export const fetchContactsSuccess = contacts => {
  return {
    type: FETCH_CONTACTS_SUCCESS,
    payload: contacts.data
  }
}

export const fetchContactsFailure = error => {
  return {
    type: FETCH_CONTACTS_FAILURE,
    payload: error
  }
}

export const changeContact = id => {
  return {
    type: CHANGE_CONTACT,
    id: id
  }
}

function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : '~';
  const name2 = contact2.name ? contact2.name.toUpperCase() : '~';
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}
