import {
  FETCH_HANDLERS_REQUEST,
  FETCH_HANDLERS_SUCCESS,
  FETCH_HANDLERS_FAILURE,
  CHANGE_HANDLER
} from './handlerDirectoryTypes';

const initialState = {
  loading: false,
  handlers: [],
  selectedID: null,
  error: ''
}

const handlerDirectoryReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_HANDLERS_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_HANDLERS_SUCCESS: return {
      ...state,
      loading: false,
      handlers: action.payload,
      error: ''
    }
    case FETCH_HANDLERS_FAILURE: return {
      ...state,
      loading: false,
      handlers: [],
      selectedID: null,
      error: action.payload
    }
    case CHANGE_HANDLER: return {
      ...state,
      selectedID: action.id
    }
    default: return state
  }
}

export default handlerDirectoryReducer;