import { FETCH_INVOICES_REQUEST, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_FAILURE, SELECT_CURRENT_INVOICE, REFRESH_INVOICES } from './invoiceTypes';

const initialState = {
  loading: false,
  invoices: [],
  initialInvoices: false,
  currentInvoice: [],
  error: ''
}

const invoiceReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_INVOICES_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_INVOICES_SUCCESS: return {
      loading: false,
      invoices: action.payload,
      initialInvoices: true,
      currentInvoice: [],
      error: ''
    }
    case FETCH_INVOICES_FAILURE: return {
      loading: false,
      invoices: [],
      initialInvoice: false,
      currentInvoice: [],
      error: action.payload
    }
    case SELECT_CURRENT_INVOICE: {
      const invoices = state.invoices;
      const index = invoices.map(invoice => invoice.id).indexOf(action.invoiceID);
      const currentInvoice = invoices[index];
      return {
        ...state,
        currentInvoice: currentInvoice,
      }
    }
    case REFRESH_INVOICES: {
      const storedInvoices = localStorage.getItem('invoices');
      if (storedInvoices) {
        try {
          const invoices = JSON.parse(storedInvoices);
          if (invoices.data && invoices.data.length > 0) {
            return {
              ...state,
              invoices: invoices.data,
              initialInvoices: true
            }
          }
          else {
            return {
              ...state,
              invoices: [],
              initialInvoices: false
            }
          }
        } catch (e) {}
      }
    }
    default: return state
  }
}

export default invoiceReducer;