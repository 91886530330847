import {
  FETCH_FORMS_PACKAGES_REQUEST,
  FETCH_FORMS_PACKAGES_SUCCESS,
  FETCH_FORMS_PACKAGES_FAILURE,
  FETCH_FORMS_PACKAGES_BY_SEASON_REQUEST,
  FETCH_FORMS_PACKAGES_BY_SEASON_SUCCESS,
  FETCH_FORMS_PACKAGES_BY_SEASON_FAILURE,
  RESET_FORMS_PACKAGES_BY_SEASON,
  CHECK_FORMS_PACKAGES,
  SELECT_CURRENT_FORMS_PACKAGE,
  SELECT_CURRENT_FORM,
  SELECT_PRIOR_FORMS_PACKAGE,
  REFRESH_FORMS_PACKAGES,
  PATCH_FORM_REQUEST,
  PATCH_FORM_SUCCESS,
  PATCH_FORM_FAILURE,
  CERTIFY_FORM_REQUEST,
  CERTIFY_FORM_SUCCESS,
  CERTIFY_FORM_FAILURE,
  SET_CERTIFIED_FORM,
  REFRESH_CERTIFIED_FORM,
  RESET_CERTIFIED_FORM,
  POST_PURCHASED_NON_US_SUCCESS,
  POST_EXPORT_BY_COUNTRY_SUCCESS
} from './formsPackageTypes';

const initialState = {
  loading: false,
  formsPackages: [],
  initialFormsPackages: false,
  formsPackagesBySeason: [],
  currentFormsPackage: [],
  currentForm: [],
  priorFormsPackage: [],
  form2references: [],
  form3references: [],
  certifiedForm: [],
  error: ''
}

const formsPackageReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_FORMS_PACKAGES_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_FORMS_PACKAGES_SUCCESS: return {
      ...state,
      loading: false,
      formsPackagesBySeason: action.payload,
      initialFormsPackages: true,
      currentFormsPackage: [],
      currentForm: [],
      error: ''
    }
    case FETCH_FORMS_PACKAGES_FAILURE: return {
      ...state,
      loading: false,
      formsPackages: [],
      initialFormsPackage: false,
      currentFormsPackage: [],
      currentForm: [],
      error: action.payload
    }
    case FETCH_FORMS_PACKAGES_BY_SEASON_REQUEST: return {
      ...state,
      loading: true,
      formsPackagesBySeason: []
    }
    case FETCH_FORMS_PACKAGES_BY_SEASON_SUCCESS: return {
      ...state,
      loading: false,
      formsPackagesBySeason: [...state.formsPackagesBySeason, action.payload],
      initialFormsPackages: true,
      currentFormsPackage: [],
      currentForm: [],
      error: ''
    }
    case FETCH_FORMS_PACKAGES_BY_SEASON_FAILURE: return {
      ...state,
      loading: false,
      formsPackagesBySeason: [],
      initialFormsPackage: false,
      currentFormsPackage: [],
      currentForm: [],
      error: action.payload
    }
    case RESET_FORMS_PACKAGES_BY_SEASON: return {
      ...state,
      formsPackagesBySeason: []
    }
    case CHECK_FORMS_PACKAGES: return {
      ...state,
      formsPackagesBySeason: action.formsPackages,
      initialFormsPackages: true
    }
    case SELECT_CURRENT_FORMS_PACKAGE: {
      const formsPackagesBySeason = state.formsPackagesBySeason;
      //console.log('SELECT CURRENT', formsPackagesBySeason);

      let seasonIndex = 0;
      let packageFound = false;
      let currentFormsPackage = null;
      while (seasonIndex < formsPackagesBySeason.length && !packageFound) {
        //let formsPackageIndex = formsPackagesBySeason[seasonIndex].map(formsPackage => formsPackage.field_form_1[0].id).indexOf(action.formID);





        let formsPackageIndex = -1;
        if (formsPackageIndex == -1) {
          formsPackageIndex = formsPackagesBySeason[seasonIndex].map(function (formsPackage) {
            if (Array.isArray(formsPackage.field_form_1)) {
              let formIDFound = null;
              formsPackage.field_form_1.forEach(function(form1) {
                if (form1.id == action.formID) {
                  formIDFound = form1.id;
                }
              });
              return formIDFound;
            }
          }).indexOf(action.formID)
        }
        if (formsPackageIndex == -1) {
          formsPackageIndex = formsPackagesBySeason[seasonIndex].map(function (formsPackage) {
            if (Array.isArray(formsPackage.field_form_2)) {
              let formIDFound = null;
              formsPackage.field_form_2.forEach(function(form2) {
                if (form2.id == action.formID) {
                  formIDFound = form2.id;
                }
              });
              return formIDFound;
            }
          }).indexOf(action.formID)
        }
        if (formsPackageIndex == -1) {
          formsPackageIndex = formsPackagesBySeason[seasonIndex].map(function (formsPackage) {
            if (Array.isArray(formsPackage.field_form_3)) {
              let formIDFound = null;
              formsPackage.field_form_3.forEach(function(form3) {
                if (form3.id == action.formID) {
                  formIDFound = form3.id;
                }
              });
              return formIDFound;
            }
          }).indexOf(action.formID)
        }




        // if (formsPackageIndex == -1) {
        //   formsPackageIndex = formsPackagesBySeason[seasonIndex].map(function (formsPackage) {
        //     if (formsPackage.field_form_2[0]) {
        //       return formsPackage.field_form_2[0].id
        //     }
        //   }).indexOf(action.formID)
        // }
        // if (formsPackageIndex == -1) {
        //   formsPackageIndex = formsPackagesBySeason[seasonIndex].map(function (formsPackage) {
        //     if (formsPackage.field_form_3[0]) {
        //       return formsPackage.field_form_3[0].id
        //     }
        //   }).indexOf(action.formID)
        // }
        if (formsPackageIndex !== -1) {  //found it
          packageFound = true;
          currentFormsPackage = formsPackagesBySeason[seasonIndex][formsPackageIndex];
        }
        else {
          seasonIndex += 1;
        }
      }
      return {
        ...state,
        currentFormsPackage: currentFormsPackage,
      }
    }
    case SELECT_CURRENT_FORM: {
      const currentFormsPackage = state.currentFormsPackage;

      let currentForm = {};
      if (Array.isArray(currentFormsPackage.field_form_1)) {
        currentFormsPackage.field_form_1.forEach(function(form1) {
          if (form1.id == action.formID) {
            currentForm = form1;
          }
        });
      }
      if (Array.isArray(currentFormsPackage.field_form_2)) {
        currentFormsPackage.field_form_2.forEach(function(form2) {
          if (form2.id == action.formID) {
            currentForm = form2;
          }
        });
      }
      if (Array.isArray(currentFormsPackage.field_form_3)) {
        currentFormsPackage.field_form_3.forEach(function(form3) {
          if (form3.id == action.formID) {
            currentForm = form3;
          }
        });
      }



      // let currentForm = [];
      // if (currentFormsPackage.field_form_1[0] && currentFormsPackage.field_form_1[0].id == action.formID) {
      //   currentForm = currentFormsPackage.field_form_1;
      // }
      // if (currentFormsPackage.field_form_2[0] && currentFormsPackage.field_form_2[0].id == action.formID) {
      //   currentForm = currentFormsPackage.field_form_2;
      // }
      // if (currentFormsPackage.field_form_3[0] && currentFormsPackage.field_form_3[0].id == action.formID) {
      //   currentForm = currentFormsPackage.field_form_3;
      // }
      return {
        ...state,
        currentForm: currentForm
      }
    }
    case SELECT_PRIOR_FORMS_PACKAGE: {
      const formsPackagesBySeason = state.formsPackagesBySeason;  //seasons are in descending order.  Most recent season first
      //console.log('SELECT PRIOR', formsPackagesBySeason);

      let seasonIndex = 0;
      let priorPackageFound = false;
      let priorFormsPackage = null;
      while (seasonIndex < formsPackagesBySeason.length && !priorPackageFound) {
        let formsPackageIndex = formsPackagesBySeason[seasonIndex].map(formsPackage => formsPackage.field_form_1[0].id).indexOf(action.formID);
        if (formsPackageIndex == -1) {
          formsPackageIndex = formsPackagesBySeason[seasonIndex].map(function (formsPackage) {
            if (formsPackage.field_form_2[0]) {
              return formsPackage.field_form_2[0].id
            }
          }).indexOf(action.formID)
        }
        if (formsPackageIndex == -1) {
          formsPackageIndex = formsPackagesBySeason[seasonIndex].map(function (formsPackage) {
            if (formsPackage.field_form_3[0]) {
              return formsPackage.field_form_3[0].id
            }
          }).indexOf(action.formID)
        }
        if (formsPackageIndex !== -1) {  //found the current package.  Go up one to prior
          if (formsPackageIndex < formsPackagesBySeason[seasonIndex].length - 1) {  //prior is in this season, up one
            priorPackageFound = true;
            priorFormsPackage = formsPackagesBySeason[seasonIndex][formsPackageIndex + 1];
          }
          else if (formsPackageIndex == formsPackagesBySeason[seasonIndex].length - 1) {  //prior is first in previous season (if it exists)
            if (seasonIndex < formsPackagesBySeason.length - 1) {  // there is a previous season to look at
              priorFormsPackage = formsPackagesBySeason[seasonIndex + 1][0];
              priorPackageFound = true;
            }
            else {
              seasonIndex += 1;
            }
          }
        }
        else {
          seasonIndex += 1;
        }
      }
      return {
        ...state,
        priorFormsPackage: priorFormsPackage,
      }
    }
    case REFRESH_FORMS_PACKAGES: {
      const storedFormsPackages = localStorage.getItem('forms_packages');
      if (storedFormsPackages) {
        try {
          const formsPackages = JSON.parse(storedFormsPackages);
          if (formsPackages.length > 0) {
            return {
              ...state,
              formsPackagesBySeason: formsPackages,
              initialFormsPackages: true
            }
          }
          else {
            return {
              ...state,
              formsPackagesBySeason: [],
              initialFormsPackages: false
            }
          }
        } catch (e) {}
      }
    }
    case PATCH_FORM_REQUEST: return {
      ...state,
      loading: true
    }
    case PATCH_FORM_SUCCESS: return {
      ...state,
      loading: false,
      error: ''
    }
    case PATCH_FORM_FAILURE: return {
      ...state,
      loading: false,
      error: action.payload
    }
    case CERTIFY_FORM_REQUEST: return {
      ...state,
      loading: true
    }
    case CERTIFY_FORM_SUCCESS: return {
      ...state,
      loading: false,
      error: ''
    }
    case CERTIFY_FORM_FAILURE: return {
      ...state,
      loading: false,
      error: action.payload
    }
    case SET_CERTIFIED_FORM: return {
      ...state,
      certifiedForm: action.payload
    }
    case REFRESH_CERTIFIED_FORM: {
      const storedCertifiedForm = localStorage.getItem('new_form_certification');
      //console.log('REFRESH CERTIFIED FORM', storedCertifiedForm);
      if (storedCertifiedForm) {
        try {
          const newCertificationID = storedCertifiedForm;
          if (newCertificationID.length > 0) {
            return {
              ...state,
              certifiedForm: newCertificationID
            }
          }
          else {
            return {
              ...state,
              certifiedForm: []
            }
          }
        } catch (e) {}
      }
    }
    case RESET_CERTIFIED_FORM: {
      localStorage.removeItem('new_form_certification');
      return {
        ...state,
        certifiedForm: []
      }
    }
    case POST_PURCHASED_NON_US_SUCCESS: {
      let form2Refs = state.form2references;
      form2Refs.push(action.payload);
      return {
        ...state,
        form2references: form2Refs
      }
    }
    case POST_EXPORT_BY_COUNTRY_SUCCESS: {
      let form3Refs = state.form3references;
      form3Refs.push(action.payload);
      return {
        ...state,
        form3references: form3Refs
      }
    }
    default: return state
  }
}

export default formsPackageReducer;
