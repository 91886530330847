import { FETCH_USER_PROFILE_REQUEST, FETCH_USER_PROFILE_SUCCESS, FETCH_USER_PROFILE_FAILURE, CHECK_USER_PROFILE } from './userTypes';

const initialState = {
  loading: false,
  username: null,
  userUID: null,
  userFirstName: null,
  handlerUID: null,
  error: ''
}

const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_USER_PROFILE_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_USER_PROFILE_SUCCESS: return {
      loading: false,
      username: action.username,
      userUID: action.userUID,
      userFirstName: action.userFirstName,
      handlerUID: action.handlerUID,
      error: ''
    }
    case FETCH_USER_PROFILE_FAILURE: return {
      ...state,
      loading: false,
      error: action.payload
    }
    case CHECK_USER_PROFILE: return {
      ...state,
      username: action.username,
      userUID: action.userUID,
      userFirstName: action.userFirstName,
      handlerUID: action.handlerUID,
    }
    default: return state
  }
}

export default userReducer;