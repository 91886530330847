import {
  FETCH_MONTHLY_REPORTS_REQUEST,
  FETCH_MONTHLY_REPORTS_SUCCESS,
  FETCH_MONTHLY_REPORTS_FAILURE,
  RESET_MONTHLY_REPORTS,
  FETCH_ANNUAL_REPORTS_REQUEST,
  FETCH_ANNUAL_REPORTS_SUCCESS,
  FETCH_ANNUAL_REPORTS_FAILURE,
} from './industryReportsTypes';

const initialState = {
  loading: false,
  monthlyReports: [],
  annualReports: [],
  error: ''
}

const industryReportsReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_MONTHLY_REPORTS_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_MONTHLY_REPORTS_SUCCESS: return {
      ...state,
      loading: false,
      monthlyReports: [...state.monthlyReports, action.payload],
      error: ''
    }
    case FETCH_MONTHLY_REPORTS_FAILURE: return {
      ...state,
      loading: false,
      monthlyReports: [],
      error: action.payload
    }
    case RESET_MONTHLY_REPORTS: return {
      ...state,
      monthlyReports: []
    }
    case FETCH_ANNUAL_REPORTS_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_ANNUAL_REPORTS_SUCCESS: return {
      ...state,
      loading: false,
      annualReports: action.payload,
      error: ''
    }
    case FETCH_ANNUAL_REPORTS_FAILURE: return {
      ...state,
      loading: false,
      annualReports: [],
      error: action.payload
    }
    default: return state
  }
}

export default industryReportsReducer;