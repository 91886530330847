import {
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  CHECK_USER_PROFILE,
  SET_HANDLER_PACKET_CONFIRM,
  // PATCH_USER_PROFILE_REQUEST,
  // PATCH_USER_PROFILE_SUCCESS,
  // PATCH_USER_PROFILE_FAILURE
} from './userTypes';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchUserProfile = (username) => {
  console.log("FETCHING USER PROFILE");
  const url = `/jsonapi/user/user?filter[name][value]=${username}`;
  //console.log(url);
  const options = {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return async (dispatch) => {
    dispatch(fetchUserProfileRequest);
    try {
      const response = await auth.fetchWithAuthentication(url, options)
      //console.log('USER RESPONSE', response);
      if (response.ok) {
        const data = await response.json();
        const userProfile = data.data;
        console.log('USER PROFILE', userProfile);
        const userUID = userProfile[0]['id'];
        const userFirstName = userProfile[0]['field_first_name'];
        //const handlerUID = userProfile[0]['relationships']['field_user_handler_company']['data']['id'];
        const handlerUID = userProfile[0]['field_user_handler_company']['id'];
        //const handlerPacketConfirm = userProfile[0]['field_handler_packet_confirm'];
        localStorage.setItem('username', username);
        localStorage.setItem('user_uid', userUID);
        localStorage.setItem('handler_uid', handlerUID);
        localStorage.setItem('user_firstname', userFirstName);
        //localStorage.setItem('handler_packet_confirm', handlerPacketConfirm);
        dispatch(fetchUserProfileSuccess(username, userUID, userFirstName, handlerUID));
      }
      else {
        throw new Error(`message: ${response.status}`);
      }
    }
    catch (error) {
      const errorMsg = error.message;
      dispatch(fetchUserProfileFailure(errorMsg));
    }
  }
}

// export const setHandlerPacketConfirm = (userUID, data) => {
//   console.log("SETTING HANDLER PACKET CONFIRM");
//   const url = `/jsonapi/user/user/${userUID}`;
//   const options = {
//     method: 'PATCH',
//     headers: new Headers({
//       'Accept': 'application/vnd.api+json',
//       'Content-Type': 'application/vnd.api+json',
//     }),
//     mode: 'cors',
//     body: JSON.stringify(data)
//   };
//   return async (dispatch) => {
//     dispatch(patchUserProfileRequest);
//     try {
//       const response = await auth.fetchWithAuthentication(url, options);
//       if (response.ok) {
//         console.log("USER PATCH SUCCESS");
//         dispatch(patchUserProfileSuccess(JSON.stringify(response)));
//         // let username= null;
//         //   try {
//         //     username = localStorage.getItem('username');
//         //   }
//         //   catch {
//         //     //throw new Error()
//         //   }
//         // await dispatch(fetchUserProfile(username));
//         //localStorage.setItem('handler_packet_confirm', true);
//       }
//       else {
//         console.log("USER PATCH FAILURE1");
//         dispatch(patchUserProfileFailure("An error has occured."));
//       }
//     }
//     catch (error) {
//       console.log("USER PATCH FAILURE2");
//       const errorMsg = error.message;
//       dispatch(patchUserProfileFailure(errorMsg));
//     }
//   }
// }

// export const fetchUserProfile = (username) => {
// 	console.log("FETCHING USER PROFILE");
//   const url = `/jsonapi/user/user?filter[name][value]=${username}`;
//   console.log(url);
//   const options = {
//     method: 'GET',
//     headers: new Headers({
//       'Accept': 'application/vnd.api+json',
//       'Content-Type': 'application/vnd.api+json',
//     }),
//     mode: 'cors',
//   };
//   return (dispatch) => {
//     dispatch(fetchUserProfileRequest);
//     auth.fetchWithAuthentication(url, options)
//       .then(response => {
//         return response.json();
//       })
//       .then(data => {
//         const userProfile = data.data;
//         const userUID = userProfile[0]['id'];
//         const handlerUID = userProfile[0]['relationships']['field_user_handler_company']['data']['id'];
//         localStorage.setItem('username', username);
//         localStorage.setItem('user_uid', userUID);
//         localStorage.setItem('ha', handlerUID);
//         dispatch(fetchUserProfileSuccess(username, userUID, handlerUID));
//       })
//       .catch(error => {
//         const errorMsg = error.message;
//         dispatch(fetchUserProfileFailure(errorMsg));
//       })
//   }
// }

export const fetchUserProfileRequest = () => {
  return {
    type: FETCH_USER_PROFILE_REQUEST
  }
}

export const fetchUserProfileSuccess = (username, userUID, userFirstName, handlerUID) => {
  //console.log('USER PROFILE SUCCESS');
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    username: username,
    userUID: userUID,
    userFirstName: userFirstName,
    handlerUID: handlerUID,
    //handlerPacketConfirm: handlerPacketConfirm,
  }
}

export const fetchUserProfileFailure = error => {
  //console.log('USER PROFILE FAILURE');
  return {
    type: FETCH_USER_PROFILE_FAILURE,
    payload: error
  }
}

export const checkUserProfile = () => {
  return {
    type: CHECK_USER_PROFILE,
    username: localStorage.getItem('username'),
    userUID: localStorage.getItem('user_uid'),
    userFirstName: localStorage.getItem('user_firstname'),
    handlerUID: localStorage.getItem('handler_uid'),
    //handlerPacketConfirm: localStorage.getItem('handler_packet_confirm')
  }
}

// export const patchUserProfileRequest = () => {
//   return {
//     type: PATCH_USER_PROFILE_REQUEST
//   }
// }

// export const patchUserProfileSuccess = patchResult => {
//   return {
//     type: PATCH_USER_PROFILE_SUCCESS,
//     payload: patchResult
//   }
// }

// export const patchUserProfileFailure = error => {
//   return {
//     type: PATCH_USER_PROFILE_FAILURE,
//     payload: error
//   }
// }


