import {
  FETCH_SEASONS_REQUEST,
  FETCH_SEASONS_SUCCESS,
  FETCH_SEASONS_FAILURE,
  CHECK_SEASONS
} from './seasonTypes';

const initialState = {
  loading: false,
  seasons: [],
  initialSeasons: false,
  currentSeason: [],
  error: ''
}

const findCurrentSeason = (seasons) => {
	let currentSeason = [];
	if (seasons !== null && seasons.length > 0) {
	  let seasonIndex = seasons.map(season => season.field_current_season).indexOf(true);
  	currentSeason = seasons[seasonIndex];
  }
  return currentSeason;
}

const seasonReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_SEASONS_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_SEASONS_SUCCESS: return {
      ...state,
      loading: false,
      seasons: action.payload,
      initialSeasons: true,
      currentSeason: findCurrentSeason(action.payload),
      error: ''
    }
    case FETCH_SEASONS_FAILURE: return {
      ...state,
      loading: false,
      seasons: [],
      initialSeasons: false,
      currentSeasons: [],
      error: action.payload
    }
    case CHECK_SEASONS: return {
      ...state,
      seasons: action.seasons,
      currentSeason: findCurrentSeason(action.seasons)
    }
    default: return state
  }
}

export default seasonReducer;