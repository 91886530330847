import {
  FETCH_INDUSTRY_ANALYTICS_REQUEST,
  FETCH_INDUSTRY_ANALYTICS_SUCCESS,
  FETCH_INDUSTRY_ANALYTICS_FAILURE,
  CHECK_INDUSTRY_ANALYTICS
} from './industryAnalyticsTypes';

import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchIndustryAnalytics = () => {

  const path = `/jsonapi/node/industry_analytics`;
  const include = `include=field_industry_analytics_xlsx`;
  const url = `${path}?${include}`;
  console.log('URL', url);
  const options = {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return async (dispatch) => {
    dispatch(fetchIndustryAnalyticsRequest);
    try {
      const response = await auth.fetchWithAuthentication(url, options)
      if (response.ok) {
        const data = await response.json();
        const industryAnalytics = data.data;

        localStorage.setItem('industry_analytics', JSON.stringify(industryAnalytics));
        await dispatch(fetchIndustryAnalyticsSuccess(industryAnalytics));
      }
      else {
        throw new Error(`message: ${response.status}`);
      }
    }
    catch (error) {
      const errorMsg = error.message;
      dispatch(fetchIndustryAnalyticsFailure(errorMsg));
    }
  }
}


export const fetchIndustryAnalyticsRequest = () => {
  console.log("FETCH INDUSTRY ANALYTICS REQUEST");
  return {
    type: FETCH_INDUSTRY_ANALYTICS_REQUEST
  }
}

export const fetchIndustryAnalyticsSuccess = (industryAnalytics) => {
  console.log("FETCH INDUSTRY ANALYTICS SUCCESS");
  return {
    type: FETCH_INDUSTRY_ANALYTICS_SUCCESS,
    industryAnalytics: industryAnalytics
  }
}

export const fetchIndustryAnalyticsFailure = error => {
  return {
    type: FETCH_INDUSTRY_ANALYTICS_FAILURE,
    payload: error
  }
}

export const checkIndustryAnalytics = () => {
  return {
    type: CHECK_INDUSTRY_ANALYTICS,
    industryAnalytics: JSON.parse(localStorage.getItem('industry_analytics'))
  }
}
