import {
  FETCH_YEAR_END_FORMS_REQUEST,
  FETCH_YEAR_END_FORMS_SUCCESS,
  FETCH_YEAR_END_FORMS_FAILURE,
  SELECT_CURRENT_YEAR_END_FORM,
  REFRESH_YEAR_END_FORMS,
  PATCH_YEAR_END_FORM_REQUEST,
  PATCH_YEAR_END_FORM_SUCCESS,
  PATCH_YEAR_END_FORM_FAILURE,
  AMEND_YEAR_END_FORM_REQUEST,
  AMEND_YEAR_END_FORM_SUCCESS,
  AMEND_YEAR_END_FORM_FAILURE,
  CERTIFY_YEAR_END_FORM_REQUEST,
  CERTIFY_YEAR_END_FORM_SUCCESS,
  CERTIFY_YEAR_END_FORM_FAILURE,
  SET_CERTIFIED_YEAR_END_FORM,
  REFRESH_CERTIFIED_YEAR_END_FORM,
  RESET_CERTIFIED_YEAR_END_FORM,
  SET_AUG_FORM1_AMEND_REQD,
  REFRESH_AUG_FORM1_AMEND_REQD,
  RESET_AUG_FORM1_AMEND_REQD
} from './yearEndTypes';
import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchYearEndForms = (handlerUID) => {
  if (handlerUID !== null) {
    const path = '/jsonapi/node/apc_form_5';
    const filter = `filter[field_handler.id]=${handlerUID}`;
    const sort = 'sort[sort-form5][path]=field_season.field_reporting_due_date&sort[sort-form5][direction]=DESC';
    const include = `include=field_season,field_form_5_pdf`;
    const url = `${path}?${filter}&${sort}&${include}`;
    //console.log("Fetch Form 5: ", url);
    
    const options = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return async (dispatch) => {
      dispatch(fetchYearEndFormsRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          const data = await response.json();
          const yearEndForms = data;
          //console.log("Form 5 Success Data: ", yearEndForms);
          localStorage.setItem('year_end_forms', JSON.stringify(yearEndForms));
          dispatch(fetchYearEndFormsSuccess(yearEndForms));
        }
        else {
          throw new Error(`message: ${response.status}`);
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(fetchYearEndFormsFailure(errorMsg));
      }
    }
  }
}

export const patchForm5 = (formID, data, augForm1AmendID) => {
  if (formID !== null) {
    const url = `/jsonapi/node/apc_form_5/${formID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(patchYearEndFormRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(patchYearEndFormSuccess(JSON.stringify(response)));
          localStorage.setItem('aug_form1_amend_reqd', augForm1AmendID);
          await dispatch(setAugForm1AmendReqd(augForm1AmendID));
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          await dispatch(fetchYearEndForms(handlerUID));
          history.push(`/dashboard/form5Certification/${formID}`);
        }
        else {
          dispatch(patchYearEndFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(patchYearEndFormFailure(errorMsg));
      }
    }
  }
}

export const amendForm5 = (formID, data, augForm1AmendID) => {
  if (formID !== null) {
    const url = `/jsonapi/node/apc_form_5`;
    const options = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(amendYearEndFormRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(amendYearEndFormSuccess(JSON.stringify(response)));
          localStorage.setItem('aug_form1_amend_reqd', augForm1AmendID);
          await dispatch(setAugForm1AmendReqd(augForm1AmendID));
          const data = await response.json();
          const revisedFormID = data.data.id;
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          await dispatch(fetchYearEndForms(handlerUID));
          history.push(`/dashboard/form5AmendCertification/${revisedFormID}`);
        }
        else {
          dispatch(amendYearEndFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(amendYearEndFormFailure(errorMsg));
      }
    }
  }
}

export const certifyForm5 = (formID, formType, data, augAmendForm1Reqd) => {
  if (formID !== null && formType !== null) {
    const url = `/jsonapi/node/${formType}/${formID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data)
    };
    return async (dispatch) => {
      dispatch(certifyYearEndFormRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(certifyYearEndFormSuccess(JSON.stringify(response)));
          localStorage.setItem('new_year_end_form_certification', formID);
          await dispatch(setNewYearEndFormCertification(formID));
          localStorage.setItem('aug_form1_amend_reqd', augAmendForm1Reqd);
          await dispatch(setAugForm1AmendReqd(augAmendForm1Reqd));
          let handlerUID = null;
          try {
            handlerUID = localStorage.getItem('handler_uid');
          }
          catch {
            //throw new Error()
          }
          await dispatch(fetchYearEndForms(handlerUID));
          history.push(`/dashboard`);
        }
        else {
          dispatch(certifyYearEndFormFailure("An error has occured."));
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(certifyYearEndFormFailure(errorMsg));
      }
    }
  }
}

export const fetchYearEndFormsRequest = () => {
  return {
    type: FETCH_YEAR_END_FORMS_REQUEST
  }
}

export const fetchYearEndFormsSuccess = fetchYearEndForms => {
  return {
    type: FETCH_YEAR_END_FORMS_SUCCESS,
    payload: fetchYearEndForms.data
  }
}

export const fetchYearEndFormsFailure = error => {
  return {
    type: FETCH_YEAR_END_FORMS_FAILURE,
    payload: error
  }
}

export const selectCurrentYearEndForm = (yearEndFormID) => {
  return {
    type: SELECT_CURRENT_YEAR_END_FORM,
    yearEndFormID: yearEndFormID
  }
}

export const refreshYearEndForms = () => {
  return {
    type: REFRESH_YEAR_END_FORMS
  }
}

export const patchYearEndFormRequest = () => {
  return {
    type: PATCH_YEAR_END_FORM_REQUEST
  }
}

export const patchYearEndFormSuccess = patchResult => {
  return {
    type: PATCH_YEAR_END_FORM_SUCCESS,
    payload: patchResult
  }
}

export const patchYearEndFormFailure = error => {
  return {
    type: PATCH_YEAR_END_FORM_FAILURE,
    payload: error
  }
}

export const amendYearEndFormRequest = () => {
  return {
    type: AMEND_YEAR_END_FORM_REQUEST
  }
}

export const amendYearEndFormSuccess = patchResult => {
  return {
    type: AMEND_YEAR_END_FORM_SUCCESS,
    payload: patchResult
  }
}

export const amendYearEndFormFailure = error => {
  return {
    type: AMEND_YEAR_END_FORM_FAILURE,
    payload: error
  }
}

export const certifyYearEndFormRequest = () => {
  return {
    type: CERTIFY_YEAR_END_FORM_REQUEST
  }
}

export const certifyYearEndFormSuccess = certifyResult => {
  return {
    type: CERTIFY_YEAR_END_FORM_SUCCESS,
    payload: certifyResult
  }
}

export const certifyYearEndFormFailure = error => {
  return {
    type: CERTIFY_YEAR_END_FORM_FAILURE,
    payload: error
  }
}

export const setNewYearEndFormCertification = formID => {
  return {
    type: SET_CERTIFIED_YEAR_END_FORM,
    payload: formID
  }
}

export const refreshNewYearEndCertification = () => {
  return {
    type: REFRESH_CERTIFIED_YEAR_END_FORM
  }
}

export const resetNewYearEndCertification = () => {
  return {
    type: RESET_CERTIFIED_YEAR_END_FORM
  }
}

export const setAugForm1AmendReqd = augForm1AmendID => {
  return {
    type: SET_AUG_FORM1_AMEND_REQD,
    payload: augForm1AmendID
  }
}

export const refreshAugForm1AmendReqd = () => {
  return {
    type: REFRESH_AUG_FORM1_AMEND_REQD
  }
}

export const resetAugForm1AmendReqd = () => {
  return {
    type: RESET_AUG_FORM1_AMEND_REQD
  }
}