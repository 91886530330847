import {
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  CHANGE_CONTACT
} from './contactsTypes';

const initialState = {
  loading: false,
  contacts: [],
  selectedID: null,
  error: ''
}

const contactsReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_CONTACTS_REQUEST: return {
      ...state,
      loading: true
    }
    case FETCH_CONTACTS_SUCCESS: return {
      ...state,
      loading: false,
      contacts: action.payload,
      error: ''
    }
    case FETCH_CONTACTS_FAILURE: return {
      ...state,
      loading: false,
      contacts: [],
      selectedID: null,
      error: action.payload
    }
    case CHANGE_CONTACT: return {
      ...state,
      selectedID: action.id
    }
    default: return state
  }
}

export default contactsReducer;