import {
  FETCH_MONTHLY_REPORTS_REQUEST,
  FETCH_MONTHLY_REPORTS_SUCCESS,
  FETCH_MONTHLY_REPORTS_FAILURE,
  RESET_MONTHLY_REPORTS,
  FETCH_ANNUAL_REPORTS_REQUEST,
  FETCH_ANNUAL_REPORTS_SUCCESS,
  FETCH_ANNUAL_REPORTS_FAILURE,
} from './industryReportsTypes';

import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchMonthlyReports = (seasonID) => {
  //const url = `/jsonapi/node/apc_form_4?filter[and-group][group][conjunction]=AND&filter[or-group][group][conjunction]=OR&filter[or-group][group][memberOf]=and-group&filter[season-filter][condition][path]=field_season.id&filter[season-filter][condition][value]=${seasonUID}&filter[season-filter][condition][memberOf]=and-group&filter[activity-filter][condition][path]=field_form_activity&filter[activity-filter][condition][value]=active&filter[activity-filter][condition][memberOf]=and-group&filter[rec-handler-filter][condition][path]=field_receiving_handler.id&filter[rec-handler-filter][condition][value]=${handlerUID}&filter[rec-handler-filter][condition][memberOf]=or-group&filter[transfer-handler-filter][condition][path]=field_transferring_handler.id&filter[transfer-handler-filter][condition][value]=${handlerUID}&filter[transfer-handler-filter][condition][memberOf]=or-group&include=field_form_4_pdf,field_receiving_handler,field_transferring_handler`;
  const path = '/jsonapi/node/monthly_position_report';
  //const filter = `filter[filter-season][condition][path]=field_reporting_period.field_season.id&filter[filter-season][condition][value]=${seasonID}`;
  const filter = `filter[filter-season][condition][path]=field_reporting_period.field_season.id&filter[filter-season][condition][value]=${seasonID}&filter[filter-portal][condition][path]=field_publish_to_portal&filter[filter-portal][condition][value]=1`;
  const sort = 'sort[sort-report][path]=field_reporting_period.field_due_date&sort[sort-report][direction]=DESC';
  //const include = `include=field_position_report_pdf,field_reporting_period.field_season`;  //memory limit when including the season
  const include = `include=field_position_report_pdf,field_reporting_period`;
  const url = `${path}?${filter}&${sort}&${include}`;
  //const url = `/jsonapi/node/monthly_position_report`;  // need to tweak this url
  console.log('Mo REPTS', url);
  const options = {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return (dispatch) => {
    dispatch(fetchMonthlyReportsRequest);
    auth.fetchWithAuthentication(url, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        const monthlyReports = data;
        //localStorage.setItem('ih_transfers', JSON.stringify(ihTransfers));   //Not sure we need to store or "refresh" these from local storage using this simple component.
        dispatch(fetchMonthlyReportsSuccess(monthlyReports));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchMonthlyReportsFailure(errorMsg));
      })
  }
}

export const fetchAnnualReports = () => {
  //const url = `/jsonapi/node/apc_form_4?filter[and-group][group][conjunction]=AND&filter[or-group][group][conjunction]=OR&filter[or-group][group][memberOf]=and-group&filter[season-filter][condition][path]=field_season.id&filter[season-filter][condition][value]=${seasonUID}&filter[season-filter][condition][memberOf]=and-group&filter[activity-filter][condition][path]=field_form_activity&filter[activity-filter][condition][value]=active&filter[activity-filter][condition][memberOf]=and-group&filter[rec-handler-filter][condition][path]=field_receiving_handler.id&filter[rec-handler-filter][condition][value]=${handlerUID}&filter[rec-handler-filter][condition][memberOf]=or-group&filter[transfer-handler-filter][condition][path]=field_transferring_handler.id&filter[transfer-handler-filter][condition][value]=${handlerUID}&filter[transfer-handler-filter][condition][memberOf]=or-group&include=field_form_4_pdf,field_receiving_handler,field_transferring_handler`;
  const path = '/jsonapi/node/annual_position_report';
  const sort = 'sort[sort-season][path]=field_season.field_season_start_date&sort[sort-season][direction]=DESC';
  const include = `include=field_position_report_pdf,field_season`;
  const url = `${path}?${sort}&${include}`;
  const options = {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return (dispatch) => {
    dispatch(fetchAnnualReportsRequest);
    auth.fetchWithAuthentication(url, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        const annualReports = data;
        dispatch(fetchAnnualReportsSuccess(annualReports));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchAnnualReportsFailure(errorMsg));
      })
  }
}

export const fetchMonthlyReportsRequest = () => {
  return {
    type: FETCH_MONTHLY_REPORTS_REQUEST
  }
}

export const fetchMonthlyReportsSuccess = monthlyReports => {
  return {
    type: FETCH_MONTHLY_REPORTS_SUCCESS,
    payload: monthlyReports.data
  }
}

export const fetchMonthlyReportsFailure = error => {
  return {
    type: FETCH_MONTHLY_REPORTS_FAILURE,
    payload: error
  }
}

export const resetMonthlyReports = () => {
  return {
    type: RESET_MONTHLY_REPORTS
  }
}

export const fetchAnnualReportsRequest = () => {
  return {
    type: FETCH_ANNUAL_REPORTS_REQUEST
  }
}

export const fetchAnnualReportsSuccess = annualReports => {
  return {
    type: FETCH_ANNUAL_REPORTS_SUCCESS,
    payload: annualReports.data
  }
}

export const fetchAnnualReportsFailure = error => {
  return {
    type: FETCH_ANNUAL_REPORTS_FAILURE,
    payload: error
  }
}
