import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from './loginTypes';

const initialState = {
  loading: false,
  loginData: [],
  error: ''
}

const loginReducer = (state = initialState, action) => {
  switch(action.type) {
    case LOGIN_REQUEST: return {
      ...state,
      loading: true
    }
    case LOGIN_SUCCESS: return {
      loading: false,
      loginData: action.payload,
      error: ''
    }
    case LOGIN_FAILURE: return {
      ...state,
      loading: false,
      error: action.payload
    }
    default: return state
  }
}

export default loginReducer;