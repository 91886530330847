export const FETCH_YEAR_END_FORMS_REQUEST = 'FETCH_YEAR_END_FORMS_REQUEST';
export const FETCH_YEAR_END_FORMS_SUCCESS = 'FETCH_YEAR_END_FORMS_SUCCESS';
export const FETCH_YEAR_END_FORMS_FAILURE = 'FETCH_YEAR_END_FORMS_FAILURE';
export const SELECT_CURRENT_YEAR_END_FORM = 'SELECT_CURRENT_YEAR_END_FORM';
export const REFRESH_YEAR_END_FORMS = 'REFRESH_YEAR_END_FORMS';
export const PATCH_YEAR_END_FORM_REQUEST = 'PATCH_YEAR_END_FORM_REQUEST';
export const PATCH_YEAR_END_FORM_SUCCESS = 'PATCH_YEAR_END_FORM_SUCCESS';
export const PATCH_YEAR_END_FORM_FAILURE = 'PATCH_YEAR_END_FORM_FAILURE';
export const AMEND_YEAR_END_FORM_REQUEST = 'AMEND_YEAR_END_FORM_REQUEST';
export const AMEND_YEAR_END_FORM_SUCCESS = 'AMEND_YEAR_END_FORM_SUCCESS';
export const AMEND_YEAR_END_FORM_FAILURE = 'AMEND_YEAR_END_FORM_FAILURE';
export const CERTIFY_YEAR_END_FORM_REQUEST = 'CERTIFY_YEAR_END_FORM_REQUEST';
export const CERTIFY_YEAR_END_FORM_SUCCESS = 'CERTIFY_YEAR_END_FORM_SUCCESS';
export const CERTIFY_YEAR_END_FORM_FAILURE = 'CERTIFY_YEAR_END_FORM_FAILURE';
export const SET_CERTIFIED_YEAR_END_FORM = 'SET_CERTIFIED_YEAR_END_FORM';
export const REFRESH_CERTIFIED_YEAR_END_FORM = 'REFRESH_CERTIFIED_YEAR_END_FORM';
export const RESET_CERTIFIED_YEAR_END_FORM = 'RESET_CERTIFIED_YEAR_END_FORM';
export const SET_AUG_FORM1_AMEND_REQD = 'SET_AUG_FORM1_AMEND_REQD';
export const REFRESH_AUG_FORM1_AMEND_REQD = 'REFRESH_AUG_FORM1_AMEND_REQD';
export const RESET_AUG_FORM1_AMEND_REQD = 'RESET_AUG_FORM1_AMEND_REQD';