import {
  FETCH_IH_TRANSFERS_REQUEST,
  FETCH_IH_TRANSFERS_SUCCESS,
  FETCH_IH_TRANSFERS_FAILURE,
  REFRESH_IH_TRANSFERS
} from './ihTransferTypes';

import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchIHTransfers = (handlerUID, seasonUID) => {
  if (handlerUID !== null && seasonUID !== null) {
    //console.log("HANDLER UID", handlerUID);
    //console.log("SEASON UID", seasonUID);
    const url = `/jsonapi/node/apc_form_4?filter[and-group][group][conjunction]=AND&filter[or-group][group][conjunction]=OR&filter[or-group][group][memberOf]=and-group&filter[season-filter][condition][path]=field_season.id&filter[season-filter][condition][value]=${seasonUID}&filter[season-filter][condition][memberOf]=and-group&filter[activity-filter][condition][path]=field_form_activity&filter[activity-filter][condition][value]=active&filter[activity-filter][condition][memberOf]=and-group&filter[rec-handler-filter][condition][path]=field_receiving_handler.id&filter[rec-handler-filter][condition][value]=${handlerUID}&filter[rec-handler-filter][condition][memberOf]=or-group&filter[transfer-handler-filter][condition][path]=field_transferring_handler.id&filter[transfer-handler-filter][condition][value]=${handlerUID}&filter[transfer-handler-filter][condition][memberOf]=or-group&include=field_form_4_pdf,field_receiving_handler,field_transferring_handler`;
    const options = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return (dispatch) => {
      dispatch(fetchIHTransfersRequest);
      auth.fetchWithAuthentication(url, options)
        .then(response => {
          return response.json();
        })
        .then(data => {
          const ihTransfers = data;
          localStorage.setItem('ih_transfers', JSON.stringify(ihTransfers));   //Not sure we need to store or "refresh" these from local storage using this simple component.
          dispatch(fetchIHTransfersSuccess(ihTransfers));
        })
        .catch(error => {
          const errorMsg = error.message;
          dispatch(fetchIHTransfersFailure(errorMsg));
        })
    }
  }
}

export const fetchIHTransfersRequest = () => {
  return {
    type: FETCH_IH_TRANSFERS_REQUEST
  }
}

export const fetchIHTransfersSuccess = ihTransfers => {
  return {
    type: FETCH_IH_TRANSFERS_SUCCESS,
    payload: ihTransfers.data
  }
}

export const fetchIHTransfersFailure = error => {
  return {
    type: FETCH_IH_TRANSFERS_FAILURE,
    payload: error
  }
}

export const refreshIHTransfers = () => {
  return {
    type: REFRESH_IH_TRANSFERS
  }
}
