import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
//import Contacts from './contacts/reducer';
//import HandlerDirectory from './handler-directory/reducer';
import testComponentReducer from './testComponent/testComponentReducer';
import loginReducer from './login/loginReducer';
import userReducer from './user/userReducer';
import handlerReducer from './handler/handlerReducer';
import invoiceReducer from './invoice/invoiceReducer';
import formsPackageReducer from './formsPackage/formsPackageReducer';
import yearEndReducer from './yearEnd/yearEndReducer';
import seasonReducer from './season/seasonReducer';
import ihTransferReducer from './ihTransfer/ihTransferReducer';
import contactsReducer from './contacts/contactsReducer';
import handlerDirectoryReducer from './handlerDirectory/handlerDirectoryReducer';
import industryReportsReducer from './industryReports/industryReportsReducer';
import industryAnalyticsReducer from './industryAnalytics/industryAnalyticsReducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  testComponentReducer,
  loginReducer,
  userReducer,
  handlerReducer,
  invoiceReducer,
  formsPackageReducer,
  yearEndReducer,
  seasonReducer,
  ihTransferReducer,
  contactsReducer,
  handlerDirectoryReducer,
  industryReportsReducer,
  industryAnalyticsReducer,
});
